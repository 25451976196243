var aTabsMin = $('.accordion-tabs-minimal'),
    aTabsMinHeight = $(aTabsMin).innerHeight();

$(document).ready(function () {
  $(window).resize(function(){
    $(aTabsMin).find('a.tab-link').unbind('click');
    useCaseTabAnimationBind();
  });
  useCaseTabAnimationBind();

  tabsAnimation();
});
function useCaseTabAnimationBind() {
  var oTabCon = $(aTabsMin).find('.is-open'),
      tabLinks = $(aTabsMin).find('.tab-link');
  if($('.device-mode-flag').is(':visible')) {
    if (oTabCon.length > 0){
      oTabCon.slideDown(500).prev('a').addClass('is-active').attr('aria-selected', 'true').promise().done(function(){
        tabLinks.each(function(){
          if (!$(this).hasClass('is-active')) {
            $(this).attr('aria-selected','false')
          }
        })
      });
    }
    else {
      tabLinks.each(function(){
        if (!$(this).hasClass('is-active')) {
          $(this).attr('aria-selected','false')
        }
      })
    }
    tabLinks.on('click', function(event) {
      var btn = $(this);
      var needOpen = !btn.hasClass('is-active');
      btn.closest('.accordion-tabs-minimal').find('.is-open').slideUp(500).promise().done(function() {
        $(this).removeClass('is-open');
        $(this).prev('.is-active').removeClass('is-active').attr('aria-selected', 'false');

        if (needOpen) {
          btn.next('div').toggleClass('is-open').children().first().show().parent().slideDown(500).promise().done(function(){
            btn.toggleClass('is-active').attr('aria-selected',$(this).attr('aria-selected')==='false'?'false':'true' );
            if ($('body').hasClass('product')) {
              var topOffsetHeight = $('#holder').height() + $('.products-subnav').height();
            }
            else {
              if ($('#holder').length < 1) {
                if ($('body').hasClass('cituscon') && $('#topbar').length > 0) {
                  var topOffsetHeight = $('#topbar').height();
                }
                else {
                  var topOffsetHeight = 0;
                }
              }
              else {
                var topOffsetHeight = $('#holder').height();
              }
            };
            $('html, body').animate({
              scrollTop: btn.offset().top - topOffsetHeight
            }, 500);
            console.log('offset: ' + topOffsetHeight);
          });
        }
      });
      return false;
    });
  }
  else {
    function accordionTabsHeight() {
      var openedTab = $('.fitted-tabs .is-open'),
          openedTabHeight = $(openedTab).innerHeight(),
          tabsFullHeight = aTabsMinHeight + openedTabHeight;
      $(openedTab).closest('.tabs-parent').css('height', tabsFullHeight + 'px');
    }
    if (oTabCon.length > 0){
      oTabCon.show().children().first().fadeIn(400, function() {
        oTabCon.prev('.tab-link').addClass('is-active').attr('aria-selected', 'true').parent().siblings().find('.tab-link').attr('aria-selected','false');
        if ($(aTabsMin).hasClass('fitted-tabs')) {
          accordionTabsHeight();
          window.addEventListener("resize", accordionTabsHeight);
        }
        else {
          return false;
        }
      });
    }
    else{
      tabLinks.first().addClass('is-active').attr('aria-selected', 'true').next().addClass('is-open').show().children().first().fadeIn().promise().done(function(){
        tabLinks.each(function(){
          if (!$(this).hasClass('is-active')) {
            $(this).attr('aria-selected','false')
          }
        })
      });
    }
    tabLinks.on('click', function(event) {
      var btn = $(this);
      if (!btn.hasClass('is-active')) {
        btn.closest('.accordion-tabs-minimal').find('.is-open').children().first().fadeOut(300).promise().done(function() {
          $(this).parent().removeClass('is-open').hide().prev('.is-active').removeClass('is-active').attr('aria-selected', 'false');
          btn.addClass('is-active').attr('aria-selected', 'true').next('.tab-content').addClass('is-open').show().children().first().fadeIn(400);
          if ($(aTabsMin).hasClass('fitted-tabs')) {
            accordionTabsHeight();
            window.addEventListener("resize", accordionTabsHeight);
          }
          else {
            return false;
          }
        });
        if (btn.is('[href^="#"')) {
          setTimeout(function () {
            if (window.location.hash != btn.attr('href')){
              window.location.hash = btn.attr('href');
            }
          }, 300);
        }
      };
      return false;
    });
  }
}

//function tabsAnimation() {
//  if ($('.tabs-minimal').find('div.is-open').length == 0){
//    $('.tabs-minimal').find('a.tab-link').first().addClass('is-active').attr({'aria-selected':'true', 'selected':''}).next().addClass('is-open').show().children().first().fadeIn();
//  }
//  else{
//    $('.tabs-minimal').find('div.is-open').show().children().first().fadeIn();
//  }
//  $('.tabs-minimal').find('a.tab-link').on('click', function(event) {
//    if (!$(this).hasClass('is-active')) {
//      var btn = $(this);
//      btn.closest('.tabs-minimal').find('.is-open').children().first().fadeOut('fast').promise().done(function() {
//        $(this).parent().removeClass('is-open').hide();
//        $(this).parent().prev('a.is-active').removeClass('is-active').removeAttr('selected').attr('aria-selected', 'false');
//        btn.addClass('is-active').attr({'aria-selected':'true', 'selected':''}).next('div').addClass('is-open').show().children().first().fadeIn('fast');
//      });
//    };
//    return false;
//  });
//}

function tabsAnimation() {
  const tabsMinimal = $('.tabs-minimal');
  const tabLinks = tabsMinimal.find('a.tab-link');
  const tabContents = tabsMinimal.find('div.tab-content');

  // Initialize tabs
  tabLinks.attr('tabindex', '0'); // Tabs are always tabbable
  tabContents.attr('tabindex', '-1').hide(); // Panels non-tabbable and hidden
  disableFocusableElements(tabContents); // Disable focus for all panel children

  const defaultTab = tabLinks.first();
  defaultTab.addClass('is-active').attr({ 'aria-selected': 'true', 'selected': '' });
  defaultTab.next('div.tab-content').addClass('is-open').show(); // Visible, but non-tabbable

  // Handle tab click
  tabLinks.on('click', function (event) {
    activateTab($(this));
    event.preventDefault();
  });

  // Keyboard handling for tabs
  tabLinks.on('keydown', function (event) {
    const currentTab = $(this);
    const currentIndex = tabLinks.index(currentTab);
    let newIndex;

    switch (event.key) {
      case 'ArrowRight':
      case 'ArrowDown':
        newIndex = (currentIndex + 1) % tabLinks.length;
        break;
      case 'ArrowLeft':
      case 'ArrowUp':
        newIndex = (currentIndex - 1 + tabLinks.length) % tabLinks.length;
        break;
      case 'Enter':
      case ' ':
        activateTab(currentTab);
        event.preventDefault();
        return;
      default:
        return;
    }

    event.preventDefault();
    tabLinks.eq(newIndex).focus();
  });

  // Function to activate a tab
  function activateTab(tab) {
    const currentTabContent = tabsMinimal.find('div.is-open');

    // Deactivate current tab and its panel
    currentTabContent.removeClass('is-open').hide().attr('tabindex', '-1');
    disableFocusableElements(currentTabContent);
    tabLinks.filter('.is-active').removeClass('is-active').removeAttr('selected').attr('aria-selected', 'false');

    // Activate the selected tab and its panel
    tab.addClass('is-active').attr({ 'aria-selected': 'true', 'selected': '' });
    const newTabContent = tab.next('div.tab-content');
    newTabContent.addClass('is-open').show().attr('tabindex', '0');
    enableFocusableElements(newTabContent);
  }

  // Helper: Disable focusable elements within a container
  function disableFocusableElements(container) {
    container.find('a, button, input, select, textarea').attr('tabindex', '-1');
  }

  // Helper: Enable focusable elements within a container
  function enableFocusableElements(container) {
    container.find('a, button, input, select, textarea').removeAttr('tabindex');
  }
}


